import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';

import Fade from '@mui/material/Fade';
import toast, { Toaster } from 'react-hot-toast';

import Facebook from '../src/Icons/facebook.svg';
import instagram from '../src/Icons/instagram.svg';
import linkedIn from '../src/Icons/linkedin.svg';
import twitter from '../src/Icons/twitter.svg';
import AddNote from '../src/images/Add note.svg';
import comingSoon from '../src/images/coming soon.svg';
import greetlineSvg from '../src/images/greetline.svg';
import myVideo from '../src/images/video.mp4';
import { saveEmailAws as saveEmail } from './utils/saveEmailAws';
import { isValidEmail } from './utils/utils';
const textLink = {
  color: '#FFFFFF',
  paddingInline: '7px',
  opacity: '0.5',
  textDecoration: 'none',
};

export default function Home() {
  const [email, setEmail] = useState('');
  const [desktop, setDesktop] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [number, setNumber] = useState(0);

  const [isChecked, setIsChecked] = useState(true);

  // this log shows too much rendering, it should be improved
  // console.log({ desktop }, { email });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    if (!isValidEmail(email)) return toast.error('Email is invalid');

    const ok = await saveEmail(email, isChecked);
    if (!ok) return toast.error('Email already exists');

    setEmail('');
    setOpen(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((prevNumber) => (prevNumber < 4 ? prevNumber + 1 : 4));
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateNumColumns = () => {
    if (window.innerWidth >= 1300) {
      setDesktop(true);
    } else if (window.innerWidth >= 1250) {
      setDesktop(true);
    } else if (window.innerWidth >= 768) {
      setDesktop(false);
    } else if (window.innerWidth >= 500) {
      setDesktop(false);
    } else if (window.innerWidth >= 350) {
      setDesktop(false);
    } else {
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateNumColumns);
    updateNumColumns();
    return () => {
      window.removeEventListener('resize', updateNumColumns);
    };
  }, []);

  return (
    <div
      style={{
        minHeight: '100vh',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div>
        <Toaster />
      </div>
      <video
        autoPlay
        muted
        playsInline
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: -1,
        }}
      >
        <source src={myVideo} type="video/mp4" />
      </video>
      <div style={{ zIndex: 1 }}>
        {/** Greetline */}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',

            justifyContent: 'center',
          }}
        >
          <img
            alt={'greetlineSvg'}
            src={greetlineSvg}
            style={{
              height: '80px',
              width: '110px',
            }}
          />
        </div>
        {/** Middle */}
        <div
          style={{
            marginLeft: '10%',
            marginRight: '10%',
          }}
        >
          {desktop && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '10%',
                marginRight: '5%',
              }}
            >
              <div></div>
              {/*Tag1 */}
              <div
                style={{
                  borderWidth: '1px',
                  padding: '2px',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  borderStyle: 'solid',
                  marginLeft: '5rem',
                  marginTop: '2rem',
                  zIndex: 999,

                  borderColor: '#F2F3F4',
                  borderRadius: '130px',

                  marginRight: '280px',

                  backgroundColor: '#285FEC',
                  opacity: number > 1 ? 1 : 0,
                  transition: 'opacity 1s',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                }}
              >
                <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                  Drive More Sales
                </p>
              </div>

              {/*Tag2 */}
              <div
                style={{
                  borderWidth: '1px',
                  padding: '2px',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  borderStyle: 'solid',
                  marginLeft: '3rem',
                  marginTop: '7rem',
                  marginRight: '-1%',
                  marginBottom: '-20px',
                  zIndex: 999,

                  borderColor: '#F2F3F4',
                  borderRadius: '130px',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  backgroundColor: '#285FEC',
                  opacity: number > 2 ? 1 : 0,

                  transition: 'opacity 1s',
                }}
              >
                <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                  Elevate customer experience
                </p>
              </div>
            </div>
          )}
          {!desktop && (
            <div
              style={{
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: '2px',
                paddingLeft: '40px',
                paddingRight: '40px',
                borderColor: '#F2F3F4',
                borderRadius: '130px',
                display: 'inline-block',
                backgroundColor: '#285FEC',
                opacity: number > 0 ? 1 : 0,
                transition: 'opacity 1s',
              }}
            >
              <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                Drive More Sales
              </p>
            </div>
          )}

          {!desktop && (
            <div
              style={{
                borderWidth: '1px',
                borderStyle: 'double',
                padding: '2px',
                paddingLeft: '20px',
                paddingRight: '20px',
                whiteSpace: 'nowrap',
                borderColor: '#F2F3F4',
                borderRadius: '30px',
                width: '240px',
                marginLeft: '15%',
                marginTop: '6%',
                backgroundColor: '#285FEC',
                display: 'inline-block',
                opacity: number > 1 ? 1 : 0,
                transition: 'opacity 1s',
              }}
            >
              <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                Elevate customer experience
              </p>
            </div>
          )}

          <div>
            <img
              alt={'comingSoon'}
              src={comingSoon}
              style={{
                width: '100%',
                position: 'relative',
                zIndex: -1,
                marginTop: desktop ? '-5%' : '4%',
                marginBottom: desktop ? '0%' : '4%',
              }}
            />
          </div>

          {/** Bottom Tags */}
          {desktop && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '10%',
                marginRight: '15%',
                marginTop: '-10%',
              }}
            >
              {/*Tag1 */}
              <div
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  padding: '2px',
                  paddingLeft: '40px',
                  paddingRight: '25px',
                  whiteSpace: 'nowrap',
                  borderColor: '#F2F3F4',
                  borderRadius: '30px',
                  marginRight: '200px',

                  backgroundColor: '#285FEC',
                  opacity: number > 0 ? 1 : 0,
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  transition: 'opacity 1s',
                }}
              >
                <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                  Personalize customer support
                </p>
              </div>

              {/*Tag2 */}
              <div
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  padding: '2px',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  whiteSpace: 'nowrap',
                  borderColor: '#F2F3F4',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  borderRadius: '30px',
                  marginTop: '65px',
                  backgroundColor: '#285FEC',
                  marginRight: '15%',
                  opacity: number > 3 ? 1 : 0,
                  transition: 'opacity 1s',
                }}
              >
                <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                  Make high-impact decisions
                </p>
              </div>
            </div>
          )}
          {!desktop && (
            <div
              style={{
                borderWidth: '1px',
                borderStyle: 'solid',
                padding: '2px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderColor: '#F2F3F4',
                borderRadius: '130px',
                backgroundColor: '#285FEC',
                display: 'inline-block',

                float: 'right',
                opacity: number > 2 ? 1 : 0,
                transition: 'opacity 1s',
              }}
            >
              <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                Personalize customer support
              </p>
            </div>
          )}
          {!desktop && (
            <div
              style={{
                borderWidth: '1px',
                borderStyle: 'double',
                padding: '2px',
                paddingLeft: '20px',
                marginLeft: '8%',
                paddingRight: '20px',
                borderColor: '#F2F3F4',
                borderRadius: '30px',

                display: 'inline-block',
                backgroundColor: '#285FEC',
                marginTop: '6%',
                opacity: number > 3 ? 1 : 0,
                transition: 'opacity 1s',
              }}
            >
              <p style={{ color: '#F2F3F4', textAlign: 'center' }}>
                Make high-impact decisions
              </p>
            </div>
          )}
        </div>
        <p
          style={{
            marginTop: desktop ? '5%' : '15%',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '24px',
            fontFmily: 'Open Sans Hebrew',
          }}
        >
          Sign up for the beta test
        </p>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
            marginBottom: desktop ? '4rem' : '2rem',
            color: 'white',
          }}
        >
          <div className="form-group" onClick={handleCheckboxChange}>
            <input type="checkbox" checked={isChecked} />
            <label
              style={{
                opacity: '0.7',
                fontFamily: 'Open Sans',
                fontSize: '14px',
              }}
            >
              Yes, I agree to receiving emails from Greetline.{' '}
            </label>
          </div>
        </div>

        {/* Input */}
        {desktop && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className="custom-input3"
              style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                outline: 'solid 1px rgba(255, 255, 255, 0.15)',
                borderRadius: '140px',
                marginTop: '1%',
                marginBottom: '1%',
                background: 'white',
                width: '535px',

                height: '73px',
                zIndex: '-1',
              }}
            ></div>
            <div
              className="custom-input2"
              style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                outline: 'solid 1px rgba(255, 255, 255, 0.41)',
                borderRadius: '140px',
                marginTop: '1%',
                marginBottom: '1%',
                background: 'white',
                width: '535px',

                height: '73px',
                zIndex: '-1',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                borderRadius: '40px',
                marginTop: '1%',
                marginBottom: '1%',
                width: '535px',

                height: '73px',
                zIndex: '1',
                position: 'absolute', // Add this line to make the inner div position fixed
              }}
            >
              <input
                style={{
                  marginLeft: '5%',
                  borderStyle: 'none',
                  borderColor: 'white',
                  width: '70%',
                  fontSize: '14px',
                  color: 'black',
                  fontFamily: 'Open Sans',
                  outline: 'none', // Remove the default outline when focused
                }}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '40px',
                  marginTop: '2%',
                  marginBottom: '2%',
                  background: 'black',
                  maxWidth: '40%',
                  minWidth: '35%',

                  height: '67px',
                  marginRight: '1%',
                  cursor: 'pointer',
                }}
                onClick={handleSubmit}
              >
                <p
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    paddingInline: '10px',
                    fontSize: '14px',
                    fontFamily: 'Open Sans ',
                  }}
                >
                  Join the beta
                </p>
              </div>
            </div>
          </div>
        )}

        {!desktop && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className="custom-input3"
              style={{
                marginTop: '1%',
                marginBottom: '1%',
                background: 'white',
                width: '90%',
                position: 'absolute',
                backgroundColor: 'transparent',
                outline: 'solid 1px rgba(255, 255, 255, 0.15)',
                borderRadius: '140px',

                zIndex: '1',
                height: '68px',
                // position: "fixed", // Add this line to make the inner div position fixed
              }}
            ></div>
            <div
              className="custom-input2"
              style={{
                zIndex: '1',
                marginTop: '1%',
                marginBottom: '1%',
                background: 'white',
                width: '90%',
                position: 'absolute',
                backgroundColor: 'transparent',
                outline: 'solid 1px rgba(255, 255, 255, 0.15)',
                borderRadius: '140px',
                height: '68px',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '40px',
                marginTop: '1%',
                marginBottom: '1%',
                background: 'white',
                width: '90%',
                zIndex: '2',
                height: '75px',
              }}
            >
              <input
                
                style={{
                  marginLeft: '3%',
                  borderStyle: 'none',
                  borderColor: 'white',
                  width: desktop ? '100%' : '100%',
                  color: 'black',
                  outline: 'none', // Remove the default outline when focused
                  paddingInline: '12px',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                }}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '40px',
                  marginTop: '2%',
                  marginRight: '4%',
                  marginBottom: '2%',
                  background: 'black',
                  maxWidth: desktop ? '45%' : '45%',
                  minWidth: desktop ? '44%' : '44%',

                  height: '60px',
                  marginLeft: '0px',
                  cursor: 'pointer',
                }}
                onClick={handleSubmit}
              >
                <p
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    // paddingInline:'1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Join the beta
                </p>
              </div>
            </div>
          </div>
        )}

        {/** Footer */}
        {desktop && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              marginTop: '3%',
              fontFamily: 'Open Sans',
              fontSize: '14px',
              justifyContent: desktop ? 'center' : 'space-between',
            }}
          >
            <div></div>
            {/* <img src={Footer} style={{

            }} /> */}
            <div style={{ marginTop: '4rem' }}>
              <a href="greetline.pages.dev" style={textLink}>
                Terms and Conditions
              </a>
              <a href="greetline.pages.dev" style={textLink}>
                Privacy Policy
              </a>
              <a
                href="greetline.pages.dev"
                style={{
                  color: '#FFFFFF',
                  paddingInline: '7px',
                  opacity: '0.5',
                  textDecoration: 'none',
                }}
              >
                Cookie Policy{' '}
              </a>
              <a href="greetline.pages.dev" style={textLink}>
                Anti-Spam Policy{' '}
              </a>
            </div>
          </div>
        )}
        {desktop && (
          <div
            style={{
              ...textLink,
              display: 'flex',
              fontSize: '14px',
              justifyContent: 'space-between ',
            }}
          >
            <p style={{ paddingLeft: '20px' }}></p>
            <p style={{ marginLeft: '10rem' }}>
              {' '}
              Copyright © 2023 Greetline LLC{' '}
              <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                All Rights Reserved
              </span>
            </p>

            {desktop && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a href="http://greetline.com/">
                  <img
                    alt={'linkedIn'}
                    src={linkedIn}
                    style={{
                      width: '20px',
                      height: '20px',
                      margin: '10px',
                      opacity: '1',
                      paddingInline: '15px',
                    }}
                  />
                </a>
                <a href="http://x.com/greetlineHQ">
                  <img
                    alt={'twitter'}
                    src={twitter}
                    style={{
                      width: '20px',
                      opacity: '1',
                      margin: '10px',
                      height: '20px',
                    }}
                  />
                </a>
                <a href="http://instagram.com/greetlineHQ">
                  <img
                    alt={'instagram'}
                    src={instagram}
                    style={{
                      width: '20px',
                      margin: '10px',
                      opacity: '1',
                      height: '20px',
                      paddingInline: '15px',
                    }}
                  />
                </a>
                <a href="http://facebook.com/greetline">
                  <img
                    alt={'Facebook'}
                    src={Facebook}
                    style={{
                      width: '20px',
                      opacity: '1',
                      margin: '10px',
                      height: '20px',
                      marginRight: '2rem',
                    }}
                  />
                </a>
              </div>
            )}
          </div>
        )}
        {!desktop && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'space-between',
              marginTop: '12%',
              paddingBottom: '30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!desktop && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <a href="http://facebook.com/greetline">
                    <img
                      alt={'Facebook'}
                      src={Facebook}
                      style={{ width: '20px', height: '20px', opacity: '0.8' }}
                    />
                  </a>
                  <a href="http://instagram.com/greetlineHQ">
                    <img
                      alt={'instagram'}
                      src={instagram}
                      style={{
                        width: '20px',
                        height: '20px',
                        paddingInline: '15px',
                        opacity: '0.8',
                      }}
                    />
                  </a>
                  <a href="http://x.com/greetlineHQ">
                    <img
                      alt={'twitter'}
                      src={twitter}
                      style={{ width: '20px', height: '20px', opacity: '0.8' }}
                    />
                  </a>
                  <a href="http://greetline.pages.dev/">
                    <img
                      alt={'linkedIn'}
                      src={linkedIn}
                      style={{
                        width: '20px',
                        height: '20px',
                        paddingInline: '15px',
                        opacity: '0.8',
                      }}
                    />
                  </a>
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10px',
                fontSize: '12px',
              }}
            >
              <a href="greetline.pages.dev" style={textLink}>
                Cookie Policy{' '}
              </a>
              <a href="greetline.pages.dev" style={textLink}>
                Anti-Spam Policy{' '}
              </a>
            </div>
            <div
              style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                whiteSpace: 'nowrap',
                flexWrap: 'wrap',
                marginTop: '10px',
                fontSize: '12px',
              }}
            >
              <div>
                <a href="greetline.pages.dev" style={textLink}>
                  Terms and Conditions
                </a>
              </div>
              <div>
                <a href="greetline.pages.dev" style={textLink}>
                  Privacy Policy
                </a>
              </div>
            </div>
            {!desktop && (
              <div
                style={{
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '1px',
                  whiteSpace: 'nowrap',
                  paddingInline: '7px',
                  opacity: '0.5',
                  textDecoration: 'none',
                  fontSize: '12px',
                }}
              >
                <p>
                  Copyright © 2023 Greetline LLC.{' '}
                  <span style={{ marginLeft: '10px' }}>
                    All Rights Reserved
                  </span>
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        ariaLabelledBy="transition-modal-title"
        ariaDescribedBy="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '10px',
              // border: '1px solid #000',
              // boxShadow: 24,
              borderColor: 'white',
            }}
            onClick={() => handleClose()}
          >
            <img
              alt={'AddNote'}
              src={AddNote}
              style={{
                width: 400,
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
