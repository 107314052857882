import { SESv2Client, CreateContactCommand } from '@aws-sdk/client-sesv2';

const client = new SESv2Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

export const saveEmailAws = async (EmailAddress, opt) => {
  const command = new CreateContactCommand({
    ContactListName: 'ContactList1',
    EmailAddress,
    UnsubscribeAll: false,
    TopicPreferences: [
      {
        TopicName: 'NewProducts',
        SubscriptionStatus: opt ? 'OPT_IN' : 'OPT_OUT',
      },
    ],
  });

  try {
    const res = await client.send(command);
    // console.log(res);
    return res?.['$metadata']?.httpStatusCode === 200;
  } catch (error) {
    console.log(error);
    return false;
  }
};
// saveEmailAws('softmuneeb3@gmail.com').then(console.log);
